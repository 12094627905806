.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  // margin-right: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;
  padding: 11px;
  cursor: pointer;
  line-height: 24px;
  @media screen and (max-width: 426px) {
    // margin: 0 0 10px 0;
    margin: 0;
  }
}

.icon {
  width: 24px;
  height: 24px;
}

.disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #17376a !important;
  background: rgb(212, 212, 212) !important;
}

.button_loader {
  position: absolute;
  bottom: 1px;
  left: 13px;
  width: 75%;
  // transform: translate(-50%, -50%);
}
