.footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}
.button {
  text-decoration: underline;
  cursor: pointer;
}
.menu {
  display: flex;
  justify-content: space-between;
}
.answer_wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;

  margin-bottom: 16px;
  padding: 20px;

  /*display: flex;*/
  align-items: center;
}
.answer_wrapper > p {
  padding: 5px 0px;
}
hr {
  margin: 20px;
}
.answer_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141422;
  text-align: justify;
  /*margin-right: 20px;*/
  flex-grow: 1;
}
.answer_button {
  display: flex;
  justify-content: flex-end;
}
.source_link {
  padding-right: 5px;
  color: #17376a;
  &:visited {
    color: #17376a;
  }
}
