.header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.title {
  font-style: normal;
  font-weight: 400;
  max-width: 150px;
  text-align: center;
}
.button {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  color: #17366a;
}
