.main {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  min-width: 310px;
  max-width: 600px;
  border-radius: 8px;
  margin: 20px;
}
.button {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 35px;
  line-height: 25px;

  background: #17376a;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 0px 8px 8px 0px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  @media screen and (max-width: 426px) {
    // width: 100%;
    padding: 0 15px;
  }
}
.textBaseInput {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 20px;
  width: 100%;
  // border: 0.2px solid #17366a;
  border: none;

  outline: none;

  &:active {
    // border: 0.2px solid #17366a;
    border: none;
  }

  &::placeholder {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #a9a9a9;
  }
  @media screen and (max-width: 426px) {
    width: 90%;
  }
}
.searchInput {
  border-right: none;
  border-radius: 8px 0 0 8px;
}
.wrapper {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  // margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;
}
.deleteIcon {
  position: absolute;
  top: 50%;
  right: 150px;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 620px) {
    right: 90px;
  }
  @media screen and (max-width: 426px) {
    right: 50px;
  }
}
.iconDisabled {
  opacity: 0;
  right: 130px;
  transform: translate(-50%, -50%) rotate(45deg);

  @media screen and (max-width: 620px) {
    right: 30px;
  }
}
.inputWithText {
  padding-right: 45px;
}
