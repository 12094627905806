.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;
  outline: none;
  padding: 5px 10px;
  cursor: pointer;
  @media screen and (max-width: 426px) {
    margin: 0 0 10px 0;
  }
}
.text {
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #141422;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.chevron_passive {
  margin-left: 15px;
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}
.chevron_active {
  margin-left: 15px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}
.select_base {
  position: absolute;
  width: 100%;
  top: 75%;
  left: 0;

  display: none;
}
.select_panel {
  background: #ffffff;
  box-shadow: 0px 5px 4px rgba(23, 55, 106, 0.15);
  border-radius: 0 0 8px 8px;
  z-index: 2;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.select_loader {
  margin-left: 10px;
  height: 18px !important;
  width: 18px !important;
}
.menu_item {
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000000 !important;
  &:hover {
    background-color: #ecf2fd !important;
  }
}
.active_source {
  display: block;
  z-index: 10;
}
.active_cost {
  display: block;
  overflow: hidden;
  z-index: 10;
}
