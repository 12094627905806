.actions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @media screen and (max-width: 426px) {
    flex-direction: column;
    font-size: 14px;
  }
}
.selectedFilters {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}
.textBaseInput {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  padding: 5px 10px;
  background-color: #ecf2fd;
  border-radius: 8px;
  border: none;
  outline: none;

  &:active {
    border: none;
  }

  &::placeholder {
    color: #a9a9a9;
  }
  @media screen and (max-width: 426px) {
    width: 100%;
  }
}
.chipinput {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 1s ease-out;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;
  margin: 5px 0px;
  @media screen and (max-width: 426px) {
    width: 100%;
  }
}
.chipbutton {
  margin-left: -30px;
  width: 30px;
  height: 30px;
}
