.base {
  cursor: pointer;
  color: #17366a;
  text-decoration: underline;
  font-weight: 600;
}
.iconed {
  display: flex;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
  @media screen and (max-width: 426px) {
    margin: 0;
  }
}
.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.moreBtn {
  padding: 20px;
  justify-self: flex-end;
  cursor: pointer;
}
.baseButton {
  padding: 15px 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0px 5px 20px #17356a86;
  }
}
.filled {
  background-color: #17366a;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 65px;
}
.outlined {
  border: 1px solid #17376a;
  box-sizing: border-box;
  border-radius: 8px;
  color: #17376a;
  padding: 10px 65px;
}
