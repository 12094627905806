* {
  margin: 0;
  padding: 0;
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 20px;
  text-align: justify;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;

  transition: all 250ms ease-in;
  &:hover {
    background-color: #ecf2fd;
    border-color: #ecf2fd;
    box-shadow: none;
    border-radius: 8px;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  @media screen and(max-width: 621px) {
    margin-bottom: 8px;
    padding: 10px 10px;
    flex-direction: column;
  }
}
.item_add_to_list {
  flex-direction: row;
}
.item_deco {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0%;
}
.item_current {
  &:hover {
    background-color: #fff;
  }
}
.avatar {
  margin-right: 15px;
  padding: 30px 15px 30px 30px;
  min-width: 31px;
  @media screen and(max-width: 621px) {
    padding: 0 15px;
    min-width: 15px;
  }
}
.title {
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;
  @media screen and(max-width: 621px) {
    font-size: 16px;
  }
}
.content {
  z-index: 3;
  font-style: normal;
  font-weight: 400;
  flex-grow: 2;
  @media screen and(max-width: 621px) {
    margin-bottom: 10px;
  }
}
.description {
  flex-grow: 1;
  @media screen and(max-width: 621px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.readmore {
  cursor: pointer;
  color: #17366a;
  text-decoration: underline;
  font-weight: 600;
}
.moreBtn {
  padding: 20px;
  justify-self: flex-end;
  cursor: pointer;
}
.item_edit {
  background-color: #ecf2fd;
  // padding: 10px 0;
}
.content {
  flex-grow: 2;
  margin-right: 10px;
}
.title_edit {
  display: block;
  border: none;
  outline: none;
  line-height: 18px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}
.description_edit {
  display: block;
  border: none;
  outline: none;
  font: 16px "Montserrat", sans-serif;
  width: 100%;
  resize: vertical;
}
.button_block {
  display: flex;
  align-items: center;
  z-index: 3;
  & div {
    margin: 0 10px;
  }
  & div:last-child {
    // margin-left: 10px;
    // margin-right: 10px;
    // margin: 0;
  }
  @media screen and(max-width: 621px) {
    // & div:last-child {
    //   margin: 0;
    // }
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
  }
}
.button_item {
  margin-right: 10px;
  cursor: pointer;
  @media screen and(max-width: 621px) {
    margin: 0;
  }
}
.edit_button_block {
  display: flex;
  align-items: center;
  & div {
    margin-right: 10px;
  }
  & div:last-child {
    // margin-left: 10px;
    // margin-right: 10px;
    // margin: 0;
  }
  @media screen and(max-width: 621px) {
    & div:last-child {
      margin: 0;
    }
    font-size: 14px;

    // flex-direction: column;
  }
}

.answers_panel {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
  border-radius: 8px;
  padding: 10px 20px;
  @media screen and(max-width: 621px) {
    padding: 10px;
  }
}
