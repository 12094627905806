.wrapper {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  background-color: #ecf2fd;
  border-radius: 8px;
  margin: 5px 5px;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
}
.text {
  line-height: 18px;
  padding-right: 5px;
}
.icon {
  width: 16px;
  height: 16px;
}
.ht {
  background-color: #ecf3f2 !important;
}
.kw {
  background-color: #f8f0f0 !important;
}
.src {
  background-color: #ecf2fd !important;
}
