.container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 70px 130px;

  @media screen and (max-width: 620px) {
    padding: 10px 16px;
    flex-direction: column;
  }
  @media screen and (max-width: 768px) and (min-width: 620px) {
    padding: 40px 80px;
  }
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  cursor: pointer;

  @media screen and (max-width: 620px) {
    margin-bottom: 10px;
    svg {
      width: 90%;
    }
  }
}
.links {
  display: flex;
  & div:first-child {
    margin-right: 10px;
  }
}
.link {
  padding-right: 50px;
}
