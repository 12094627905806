.panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f6f7fc;
  border-radius: 8px;
  box-shadow: 24px;
  padding: 55px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 426px) {
    width: 290px;
    padding: 20px 0;
  }
}
.emailInput {
  border-radius: 8px;
  margin-bottom: 25px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(23, 55, 106, 0.15);
}
.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 160.9%;
  text-align: center;
  color: #000000;
  margin-bottom: 25px;
}
.saveMailButton {
  background: #17376a;
  border-radius: 8px;
  padding: 10px 60px;
}
.button_group {
  display: flex;

  & button:first-child {
    margin-right: 25px;
  }
  @media screen and (max-width: 426px) {
    & button:first-child {
      margin: 0 0 10px 0;
    }
    width: 90%;
    flex-direction: column;
  }
}
