.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.button {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  color: #17366a;
}
