* {
  margin: 0;
  padding: 0;
}
.item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 3px 10px 3px 0;
  margin-bottom: 15px;
  // cursor: pointer;
  text-align: justify;

  transition: all 250ms ease-in;

  &:hover {
    background-color: #ecf2fd;
    border-color: #ecf2fd;
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  @media screen and(max-width: 621px) {
    margin-bottom: 8px;
    flex-direction: column;
    align-items: flex-end;
    border-bottom: 0.2px solid #17356a33;
  }
}
.title {
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
  @media screen and(max-width: 621px) {
    font-size: 16px;
  }
}
.description {
  font-style: normal;
  font-weight: 400;
  a {
    padding-left: 5px;
  }
  @media screen and(max-width: 621px) {
    font-size: 14px;
  }
}
.readmore {
  cursor: pointer;
  color: #17366a;
  text-decoration: underline;
  font-weight: 600;
}
.controls {
  display: flex;
  align-items: center;
  // padding: 0 25px;
  @media screen and(max-width: 621px) {
    padding: 0;
  }
}
.controls_text {
  font-style: normal;
  font-weight: 400;
}
.content {
  flex-grow: 1;
  align-self: flex-start;
}
